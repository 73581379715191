import restReq from './public.js'

/**
*  注册登录的rest接口
*
*/

class RL {

constructor () {
  //console.log("constructor");
}

//机构名称，用户名称，身份证号，手机号，密码
register(groupFullName, userName, cardNum, phoneNum, passwd) {
  console.log("register", groupFullName, userName, cardNum, phoneNum, passwd);

  let aurl = window.configs.svcJifenProt + '://' + window.configs.svcJifenIpPort +"/txn/rl"

  let ri = {
    id: 0,
    gn: groupFullName,
    un: userName,
    cn: cardNum,
    pn: phoneNum,
    pw: passwd,
    st: "REG",//REG(register), NORMAL, WOFF(write-off), LOCKED
    ei: "",
    tc: "",
  };

  let params = JSON.stringify({
    i: "ZC",
    d: JSON.stringify(ri),
  });

  return restReq ({
    url: aurl,
    method: "post",
    data: params,
  });
}

//
login(groupId, loginAcct, passwd) {
  console.log("login", groupId, loginAcct, passwd);

  let aurl = window.configs.svcJifenProt + '://' + window.configs.svcJifenIpPort +"/login"

  let li = {
    g: groupId,
    a: loginAcct,
    p: passwd,
    e: "",
  };

  let params = JSON.stringify({
    c: "POST",
    d: li,
  });

  return restReq ({
    url: aurl,
    method: "post",
    data: params,
  });
}


}

export default RL;
