import axios from 'axios'
import { MessageBox } from 'element-ui';

//
const restSvc = axios.create({
  baseURL: "",
  timeout: 10000, //ms
  //withCredentials: true,
  headers :{
    'Content-Type': 'application/json',
    //'token': '',
    //'X-Requested-With': 'XMLHttpRequest',
  },
})

restSvc.interceptors.request.use(
  function (config) {
    //发送请求前处理
    return config
  },

  function (error) {
    //处理请求错误
    console.log(error);
    return Promise.reject(error)
  },
)

restSvc.interceptors.response.use(
  function (response) {
    //console.log(response);

    const dataAxios = response.data;
    //const code = dataAxios.reset

    //console.log("code",code);
    return dataAxios
  },
  function (error) {
    console.log(error);
    if (error.code == "ERR_NETWORK") {
      MessageBox("网络故障: 请检查网络是否连通");
    }

    return Promise.reject(error);
  }
)

export default restSvc


