<template>
<div style="margin:auto; width: 30%">

<!--el-row style="padding-top:0px;">
  <div style="margin: auto; width:70%; font-size: 18px;">
    <img  style="width:350px; margin-top: -20px" alt="logo" src="../assets/logo2.png">
  </div>
</el-row-->

<el-row style="padding-top:40px;">
  <div style="margin: auto; width: 80px; color: orange; font-size: 20px;"> 用户注册</div>
</el-row>

<el-row style="padding-top:20px;">
<el-form label-position="right" label-width="80px" :model="regiInfo">
  <el-form-item label="机构名称">
    <el-input v-model="regiInfo.groupName" placeholder="机构名称(即企事业单位名称)" @blur="chkGroup" ></el-input>
  </el-form-item>
  <el-form-item label="手机号码">
    <el-input v-model="regiInfo.phoneNum"></el-input>
  </el-form-item>
  <el-form-item label="真实姓名">
    <el-input v-model="regiInfo.userName"></el-input>
  </el-form-item>
  <el-form-item label="身份证号">
    <el-input v-model="regiInfo.card"></el-input>
  </el-form-item>
  <el-form-item label="登录密码">
    <el-input type="password" v-model="regiInfo.password" placeholder="6~12个字符" ></el-input>
  </el-form-item>
  <el-form-item label="确认密码">
    <el-input type="password" v-model="regiInfo.qrPassword"></el-input>
  </el-form-item>
</el-form>
</el-row>

<el-row style="padding-top:30px;">
  <div style="margin: auto; width: 280px; ">
    <el-button style="width:280px" type="primary" size="large" @click="register" >立即注册</el-button >
  </div>
</el-row>

<el-row style="padding-top:20px;">
  <div style="margin: auto; width: 280px; ">
    <el-button style="width:280px" type="primary" size="large" @click="cancel" >取消</el-button >
  </div>
</el-row>

<el-row style="padding-top:20px;">
<div>
1 注册成功之后，将返回首页。之后，就可以在首页使用手机号码和密码登陆。 <br>
2 点击取消，则直接返回首页。
</div>
</el-row>

</div>
</template>
<script>
import RL from '../reqs/RL.js'
import JiGou from '../reqs/jiGou.js'

export default {
name: 'Login',

components: {
},

data() {
return {
  regiInfo:{},

  rl: {}, //注册登录接口
  jg: {}, //机构接口

  groupId: "",
  groupName: "",
  loginAcct: "",
  password: "",
};
},

mounted() {
  console.log("in mounted");

  //this.groupId = 1000001;
  //this.loginAcct = "1861001";

  this.rl = new RL();
  this.jg = new JiGou();
},

methods:{
chkGroup() {
  //console.log("chkGroup");
/*
  if (this.groupName == "") {
    this.$message("机构名称不能为空");
  }

  //查询机构信息，获取机构id
  this.jg.getByName(this.groupName)
  .then((res) => {
    if (res.code != "200000") {
      if (res.code == "EJF4") {
        this.$message("获取机构信息失败: 服务执行中错误:EJF4");
      } else if (res.code == "EJF12") {
        this.$message("获取机构信息失败: 没有该机构");
      } else {
        this.$message("获取机构信息失败: 未知错误");
      }

      return;
    }
   
    this.groupId = res.data.id;
  })
  .catch(err => {
    this.$message("登录失败: 不能获取机构信息");
    console.log("exception:", err.toString());
  });
*/
},

register() {
  console.log("register");

  if (this.regiInfo.groupName == "") {
    this.$message("机构名称不能为空");
		return;
  }

  if (this.regiInfo.userName == "") {
    this.$message("真实姓名不能为空");
		return;
  }

  if (this.regiInfo.card == "") {
    this.$message("身份证号不能为空");
		return;
  }

  if (this.regiInfo.phoneNum == "") {
    this.$message("手机号不能为空");
		return;
  }

  if (this.regiInfo.password == "") {
    this.$message("登录密码不能为空");
		return;
  }

  if (this.regiInfo.qrPassword == "") {
    this.$message("确认密码不能为空");
		return;
  }

  if (this.regiInfo.qrPassword != this.regiInfo.password) {
    this.$message("输入的登录密码与确认密码不一样, 请重新输入");
		return;
  }

  if (this.regiInfo.password.length < 6 || this.regiInfo.password.length > 12) {
    this.$message("登录密码长度在6到12个字符之间");
		return;
  }

  /*if (this.regiInfo.card.length != 18 ) {
    this.$message("身份证号码长度");
		return;
  }*/

  this.rl.register(this.regiInfo.groupName, this.regiInfo.userName, this.regiInfo.card, this.regiInfo.phoneNum, this.regiInfo.password)
  .then((res) => {
    //console.log(res);
    if (res.code == "200000") {
      this.$message("注册成功");
			this.toHome();
    } else {
      this.$message("注册失败: " + res.code);
    }
  })
  .catch(err => {
    this.$message("注册失败: 网络或者服务故障");
    console.log("exception:", err.toString());
  });
},

cancel() {
  this.toHome();
},

toHome() {
  this.$router.push({path: '/', query:{x: ""}});
},

}

}
</script>

