import restReq from './public.js'

/**
*  机构的rest接口
*
*/

class JiGou {

constructor () {
  //console.log("constructor");
}

getByName(name) {
  let aurl = window.configs.svcJifenProt + '://' + window.configs.svcJifenIpPort +"/jg?c=NM" + "&n=" + name

  return restReq ({
    url: aurl,
    method: "get",
  });
}

}

export default JiGou;
